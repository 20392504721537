<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Price check</title>
    <path
      d="M12 13V9C12 8.45 11.55 8 11 8H7V6H12V4H9.5V3H7.5V4H6C5.45 4 5 4.45 5 5V9C5 9.55 5.45 10 6 10H10V12H5V14H7.5V15H9.5V14H11C11.55 14 12 13.55 12 13Z"
      fill="currentColor"
    />
    <path
      d="M19.5899 12.52L13.9299 18.17L11.0999 15.34L9.68994 16.76L13.9299 21L20.9999 13.93L19.5899 12.52Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'ic-price-check',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>
